<template>
  <section>
    <div>
      <div class="w-full flex justify-between">
        <div>
          <label for="" class="text-xs text-gray-600 italic">Lista seleccionada</label>
          <div class="p-2 border rounded-md">
            <p>
              {{ contratosStore._articulos.lista_precio_id.Nombre }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="contratosStore._medicamentos_ingresados.length" class="p-2 rounded-md bg-gray-300 text-blue-600  mt-4 italic text-sm">
        <p>Se cargaron <span class="font-bold">{{ contratosStore._medicamentos_ingresados.length }}</span> artículos</p>
      </div>
      <div class="w-full mt-4 py-2">
        <div class="w-full">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filtros.ItemCode.value" @input="getArticulos" placeholder="Buscar articulo" />
          </span>
        </div>
        <DataTable
          :value="contratosStore._paginado_articulos.rows"
          class="text-xs p-datatable-sm mt-2"
          showGridlines
        >
          <Column field="ItemCode" header="Medicamentos"></Column>
          <Column field="ItemName" header="Nombre"></Column>
          <Column field="UserText" header="Descripción"></Column>
          <!-- <Column field="U_PHR_Concentracion" header="Concentración"></Column> -->
          <Column field="U_PHR_PrincActivo" header="Principio activo"></Column>
          <Column field="U_PHR_PrincActivo" header="Estado">
            <template #body="slotProps">
              <InputSwitch @change="cambiarEstadoArticulo(slotProps.data)" v-model="slotProps.data.Status" />
            </template>
          </Column>
          <!-- <Column field="U_PHR_PrincActivo" header="Estado"></Column> -->
        </DataTable>
        <Paginator :rows="limit" @page="onPage($event)" :totalRecords="contratosStore._paginado_articulos.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog modal v-model:visible="displayModalCrearArticulo" header="Crear articulo" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <label class="w-full text-xs text-gray-600 italic" for="itemCode">Codigo del articulo</label>
        <InputText class="w-full mb-2" id="itemCode" type="text" v-model="dataCrearArticulo.ItemCode" />
        <label class="w-full text-xs text-gray-600 italic" for="tipo">Tipo</label>
        <Dropdown id="tipo" v-model="dataCrearArticulo.tipo" :options="tiposArticulo" class="w-full" />
        <div class="mt-4 flex justify-end">
          <Button @click="cerrarModal" severity="secondary" label="Cancelar" />
          <Button @click="crearArticulo" label="Guardar" />
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useListaPrecioStore } from '../../../stores/lista-precio.store'
  import { ref, onMounted, computed } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  import { useToast } from 'primevue/usetoast'
  import { useRoute } from 'vue-router'
  import ServiceContrato from '../../../services/contrato-precio.service'
  import XLSX from 'xlsx'
  import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  export default {
    name: 'crearContratoPacientes',
    setup () {
      const toast = useToast()
      const route = useRoute()
      const offset = ref(0)
      const limit = ref(10)
      const displayModalCrearArticulo = ref(false)
      const params = computed(() => {
        return {
          offset: offset.value,
          limit: limit.value
        }
      })
      const dataCrearArticulo = ref({
        ItemCode: '',
        tipo: ''
      })
      const tiposArticulo = ref(['CRONICO', 'AGUDO'])
      const contratosStore = useContratosStore()
      const listaPrecioStore = useListaPrecioStore()
      const _serviceContrato = new ServiceContrato()
      const listaSeleccionada = ref()
      const file = ref()
      const prueba = ref()
      const filtros = ref({
          ItemCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const subirArticulos = () => {
        const archivo = file.value.files[0]
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            const result = event.target.result
            const dataOne = new Uint8Array(result)
            const workbook = XLSX.read(dataOne, { type: 'array', cellDates: true })
            const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
            const data = XLSX.utils.sheet_to_json(firstWorksheet, { header: 2, raw: false, dateNf: dayjs('DD/MM/YYYY').format('YYYY-MM-DD') })
            contratosStore.articulos.articulos = []
            contratosStore.medicamentos_ingresados = []
            if (!data.length) return
            if (!data.some(a => a.ItemCode)) {
              contratosStore.articulos.articulos = []
              contratosStore.medicamentos_ingresados = []
              file.value = null
              toast.add({ severity: 'error', summary: 'Error', detail: 'El archivo que ingresaste no coincide con las columnas de la plantilla', life: 5000 })
            } else {
              for (const i of data) {
                contratosStore.articulos.articulos.push(Object.getOwnPropertyDescriptor(i, 'ItemCode').value)
                contratosStore.medicamentos_ingresados.push(i)
              }
            }
            const validaPac = await _serviceContrato.validArticulos(data)
            if (validaPac.data) {
              Swal.fire({
                icon: 'warning',
                title: 'Validacion Pacientes',
                text: validaPac.data
              })
            }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const buscarListas = ({ query }) => {
        listaPrecioStore.buscarListaPrecio(query || 'a')
      }
      const eliminarArchivo = () => {
        contratosStore.medicamentos_ingresados = []
        contratosStore.articulos.articulos = []
      }
      const getArticulos = () => {
        const busqueda = {}
        for (const [key, value] of Object.entries(filtros.value)) {
          if (value.value) {
            busqueda[key] = value.value
          }
        }
        const parametros = {
          ...params.value,
          ...busqueda
        }
        contratosStore.paginarArticulos(route.params.id, parametros)
      }
      const onPage = ({ first }) => {
        offset.value = first
        getArticulos()
      }
      const crearArticulo = () => {
        contratosStore.crearArticulosContrato(route.params.id, [dataCrearArticulo.value]).then((data) => {
          if (data) {
            Swal.fire({
              title: 'Guardado',
              text: data,
              icon: 'success'
            })
            cerrarModal()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al ingresar el articulo'
            })
          }
        })
      }
      const cerrarModal = () => {
        displayModalCrearArticulo.value = false
        dataCrearArticulo.value = {
          ItemCode: '',
          tipo: ''
        }
      }
      const cambiarEstadoArticulo = (articulo) => {
        console.log('el articulo-->', articulo)
        contratosStore.cambiarEstadoArticuloContrato(route.params.id, articulo.Id, { Status: articulo.Status }).then(() => {
          toast.add({ severity: 'info', summary: 'Estado cambiado con éxito', detail: `Se cambo el estado del medicamento ${articulo.ItemCode} a ${articulo.Status ? 'Activo' : 'Inactivo'}`, life: 5000 })
          getArticulos()
        })
      }
      onMounted(() => {
        listaPrecioStore.listarListasPrecio()
        getArticulos()
      })
      return {
        contratosStore,
        listaPrecioStore,
        subirArticulos,
        file,
        buscarListas,
        listaSeleccionada,
        filtros,
        prueba,
        eliminarArchivo,
        limit,
        offset,
        onPage,
        getArticulos,
        displayModalCrearArticulo,
        dataCrearArticulo,
        tiposArticulo,
        cerrarModal,
        crearArticulo,
        cambiarEstadoArticulo
      }
    }
  }
</script>
